export const DICTIONARY = [
  "abaix",
  "abanz",
  "abaxo",
  "abete",
  "abiar",
  "abiso",
  "ablor",
  "abono",
  "abozo",
  "abril",
  "abrío",
  "abugo",
  "achar",
  "achén",
  "áchil",
  "achio",
  "actor",
  "acuse",
  "adaza",
  "adiar",
  "adiós",
  "adoba",
  "adobo",
  "adoga",
  "adote",
  "aduya",
  "afaño",
  "afear",
  "afixo",
  "afogo",
  "afora",
  "aforo",
  "agafe",
  "agape",
  "agata",
  "aglor",
  "Agnés",
  "agora",
  "ágora",
  "agozo",
  "agraz",
  "agror",
  "aguar",
  "aiba!",
  "aibá!",
  "aibar",
  "aigua",
  "aimar",
  "Ainés",
  "airaz",
  "airón",
  "airot",
  "aixir",
  "ajada",
  "alada",
  "alaxa",
  "albar",
  "albor",
  "álbum",
  "albur",
  "alcol",
  "aldar",
  "alera",
  "aleta",
  "aleve",
  "alfil",
  "algún",
  "aliar",
  "alias",
  "alica",
  "aliga",
  "alito",
  "almud",
  "almut",
  "almuz",
  "aloda",
  "aloda",
  "aloix",
  "alora",
  "alsar",
  "altar",
  "alzar",
  "alzau",
  "amaca",
  "amago",
  "amare",
  "ambra",
  "ambre",
  "ameba",
  "amiro",
  "ampra",
  "ampre",
  "anada",
  "añada",
  "añata",
  "ancho",
  "ancla",
  "ancón",
  "andán",
  "andar",
  "aneba",
  "aneda",
  "anede",
  "anelo",
  "añeta",
  "aneto",
  "angar",
  "anglo",
  "anima",
  "animo",
  "añino",
  "anodo",
  "anque",
  "ansar",
  "ansia",
  "ansir",
  "antes",
  "antis",
  "antro",
  "anual",
  "anuei",
  "anuet",
  "anzía",
  "aorro",
  "aorta",
  "apaño",
  "apear",
  "apego",
  "apero",
  "apuro",
  "aquel",
  "aquer",
  "arabe",
  "árbol",
  "arcón",
  "arder",
  "ardit",
  "ardor",
  "arena",
  "argez",
  "argón",
  "argot",
  "aribo",
  "aries",
  "ariet",
  "arizo",
  "armar",
  "armos",
  "arnal",
  "arnés",
  "aroma",
  "arpón",
  "arras",
  "arreo",
  "arres",
  "arrés",
  "arreu",
  "arriu",
  "arrós",
  "arroz",
  "artal",
  "artar",
  "arzén",
  "arzón",
  "asare",
  "asayo",
  "ascla",
  "ascos",
  "asero",
  "aseyo",
  "asial",
  "asila",
  "asilo",
  "asina",
  "asomo",
  "aspar",
  "áspid",
  "astro",
  "atajo",
  "ataúl",
  "atine",
  "atino",
  "atlas",
  "atomo",
  "atona",
  "atril",
  "atriz",
  "atrós",
  "atroz",
  "ature",
  "aucar",
  "audaz",
  "augua",
  "aunir",
  "aupar",
  "aurón",
  "ausén",
  "ausín",
  "autor",
  "aveza",
  "aviar",
  "avién",
  "avión",
  "aviso",
  "axada",
  "axizo",
  "axuar",
  "ayere",
  "ayuno",
  "ayuta",
  "azaar",
  "azero",
  "azers",
  "azeso",
  "azión",
  "azote",
  "azute",
  "babel",
  "babol",
  "babón",
  "babor",
  "bacal",
  "bacán",
  "badal",
  "badar",
  "badén",
  "badía",
  "badil",
  "bagre",
  "baile",
  "baixo",
  "bajar",
  "bajes",
  "balda",
  "balde",
  "balea",
  "balet",
  "balín",
  "ballo",
  "balón",
  "balot",
  "balsa",
  "balso",
  "bamba",
  "bambú",
  "bañar",
  "banca",
  "banco",
  "banda",
  "bando",
  "bandó",
  "banos",
  "baños",
  "banzo",
  "barba",
  "barbo",
  "barca",
  "barco",
  "barda",
  "bardo",
  "barga",
  "bario",
  "barón",
  "barot",
  "barra",
  "barsa",
  "barso",
  "barza",
  "basal",
  "basar",
  "basca",
  "basón",
  "basta",
  "baste",
  "basté",
  "batán",
  "batea",
  "bater",
  "batir",
  "batoc",
  "baxán",
  "baxar",
  "bazar",
  "bazía",
  "bazín",
  "beber",
  "becar",
  "becha",
  "beire",
  "belar",
  "belas",
  "belén",
  "beliu",
  "bella",
  "bello",
  "belún",
  "bemol",
  "benar",
  "benda",
  "beras",
  "berra",
  "berro",
  "berza",
  "besar",
  "betún",
  "beure",
  "bezar",
  "bibón",
  "bicho",
  "bidet",
  "bidón",
  "bieco",
  "biega",
  "biela",
  "biera",
  "bigós",
  "bigot",
  "bilis",
  "billa",
  "binar",
  "binsa",
  "binza",
  "biola",
  "birla",
  "birol",
  "birot",
  "bisbe",
  "bisca",
  "bisco",
  "bisel",
  "bisón",
  "bispe",
  "bispo",
  "blaga",
  "bleto",
  "bloso",
  "boato",
  "bobón",
  "bocal",
  "bocau",
  "bocha",
  "boche",
  "bocoi",
  "bofio",
  "bogal",
  "bogar",
  "boina",
  "boira",
  "bolán",
  "boldo",
  "bolet",
  "bolla",
  "bollo",
  "boloc",
  "bolón",
  "bolra",
  "bolsa",
  "bolso",
  "bolva",
  "bomba",
  "bombe",
  "bombé",
  "bombo",
  "boñar",
  "bonet",
  "bonzo",
  "boque",
  "borda",
  "borde",
  "borra",
  "bosar",
  "bosón",
  "botar",
  "botín",
  "botón",
  "bozar",
  "bozón",
  "braga",
  "brama",
  "brasa",
  "braso",
  "braza",
  "brazo",
  "breca",
  "breco",
  "brena",
  "breña",
  "breno",
  "brete",
  "brian",
  "briba",
  "brién",
  "brilo",
  "brisa",
  "broca",
  "broma",
  "brosa",
  "brote",
  "broxa",
  "broxo",
  "broya",
  "broza",
  "bruco",
  "bruir",
  "bruno",
  "brusa",
  "bruxa",
  "bubaz",
  "bucal",
  "buche",
  "bucho",
  "bucle",
  "budín",
  "buega",
  "bueña",
  "bueño",
  "buevo",
  "bufar",
  "bufes",
  "bufet",
  "bufiu",
  "bufón",
  "bufos",
  "buixe",
  "buixo",
  "bulbo",
  "bulco",
  "bulla",
  "bulre",
  "bulto",
  "bunda",
  "buque",
  "bural",
  "burbú",
  "bureu",
  "burla",
  "burno",
  "burra",
  "busca",
  "busto",
  "butir",
  "buxía",
  "buyol",
  "buzón",
  "cabal",
  "caber",
  "cable",
  "cabra",
  "cabre",
  "cacal",
  "cacau",
  "cacha",
  "cacho",
  "cadet",
  "cafir",
  "cafiz",
  "cafre",
  "cagar",
  "caída",
  "caimo",
  "caire",
  "caixa",
  "caixo",
  "calar",
  "calca",
  "calco",
  "calda",
  "caldé",
  "caldo",
  "calén",
  "caler",
  "caliu",
  "cáliz",
  "calle",
  "callo",
  "calma",
  "caloi",
  "calor",
  "calre",
  "calso",
  "calza",
  "calzo",
  "camal",
  "camau",
  "cambo",
  "camín",
  "camón",
  "campo",
  "canal",
  "canar",
  "cañar",
  "cando",
  "canet",
  "canoa",
  "canon",
  "cañón",
  "canta",
  "canto",
  "caoba",
  "capar",
  "capás",
  "capaz",
  "caper",
  "capil",
  "capir",
  "capón",
  "capot",
  "capuz",
  "caqui",
  "cardo",
  "carga",
  "cargo",
  "carín",
  "cariz",
  "carne",
  "carpa",
  "carpo",
  "Carro",
  "carro",
  "carta",
  "casal",
  "casar",
  "casca",
  "casco",
  "caspa",
  "casta",
  "catar",
  "catén",
  "catre",
  "causa",
  "causo",
  "cauto",
  "cavar",
  "caxal",
  "caxar",
  "caxón",
  "caxot",
  "cayer",
  "cazar",
  "cazil",
  "cazol",
  "cazón",
  "chaca",
  "chaco",
  "chada",
  "chama",
  "chapa",
  "chapo",
  "chaus",
  "chaza",
  "chelo",
  "chema",
  "Chema",
  "chepa",
  "chera",
  "chesa",
  "cheso",
  "cheta",
  "chiba",
  "chilo",
  "chiné",
  "chiro",
  "chita",
  "chito",
  "chiva",
  "choca",
  "chola",
  "choro",
  "chous",
  "chove",
  "choza",
  "Chuan",
  "chubo",
  "chuco",
  "chugo",
  "chupa",
  "chupo",
  "churi",
  "Chusé",
  "chuta",
  "chuza",
  "chuzo",
  "clapa",
  "clase",
  "clava",
  "clavo",
  "cleca",
  "clero",
  "cleta",
  "clica",
  "clima",
  "clise",
  "clixé",
  "cllau",
  "cllim",
  "cllot",
  "clluc",
  "cloca",
  "cloro",
  "closa",
  "clota",
  "clusa",
  "cobar",
  "cobil",
  "cobol",
  "cobra",
  "cobro",
  "coche",
  "cocón",
  "cocut",
  "codoñ",
  "cofia",
  "cofre",
  "cogol",
  "coito",
  "colar",
  "colla",
  "collé",
  "color",
  "comai",
  "comer",
  "cómic",
  "comín",
  "común",
  "coñac",
  "copar",
  "copia",
  "copla",
  "copol",
  "copón",
  "coral",
  "corbo",
  "corca",
  "corco",
  "corno",
  "corro",
  "corte",
  "corva",
  "cosca",
  "coser",
  "cosir",
  "cosme",
  "costa",
  "costo",
  "cotón",
  "coxín",
  "coxón",
  "cozer",
  "cozio",
  "craba",
  "crapa",
  "crear",
  "creba",
  "credo",
  "crema",
  "crera",
  "cresa",
  "creso",
  "criar",
  "crica",
  "crier",
  "crobe",
  "cruel",
  "cruze",
  "cuasi",
  "cuatí",
  "cucar",
  "cucuc",
  "cucut",
  "cudir",
  "cuero",
  "cueva",
  "cueza",
  "cuezo",
  "cuita",
  "cuixa",
  "cular",
  "culpa",
  "culre",
  "cunar",
  "cuñar",
  "cupón",
  "curar",
  "curda",
  "curia",
  "curio",
  "cursa",
  "curso",
  "curte",
  "curto",
  "cusir",
  "cutis",
  "cutre",
  "dable",
  "dalla",
  "dalle",
  "dallo",
  "damón",
  "danza",
  "danze",
  "dátil",
  "debán",
  "debat",
  "deber",
  "débil",
  "debut",
  "delir",
  "dellá",
  "d'els",
  "delta",
  "demás",
  "demba",
  "demén",
  "demés",
  "dende",
  "derré",
  "desde",
  "deuda",
  "deure",
  "deuta",
  "dexar",
  "dezén",
  "dezir",
  "dezir",
  "Diago",
  "diana",
  "didal",
  "dieta",
  "dillá",
  "dinar",
  "diner",
  "disco",
  "dital",
  "ditar",
  "diván",
  "dixar",
  "d'ixo",
  "dizir",
  "doble",
  "doblé",
  "dogma",
  "dólar",
  "doler",
  "dolor",
  "domar",
  "donar",
  "dople",
  "dorar",
  "dorso",
  "dosis",
  "dotar",
  "dotse",
  "dozén",
  "dózil",
  "drama",
  "drapo",
  "dresa",
  "droga",
  "duana",
  "ducha",
  "dudar",
  "duelo",
  "Dulza",
  "dulze",
  "duque",
  "durar",
  "echar",
  "edema",
  "efebo",
  "elize",
  "enema",
  "enlaz",
  "enoyo",
  "entra",
  "entre",
  "entro",
  "enuyo",
  "envas",
  "envés",
  "epica",
  "epoca",
  "eraje",
  "erizo",
  "errán",
  "errar",
  "error",
  "erzio",
  "esbar",
  "esbol",
  "escai",
  "escar",
  "esfer",
  "esgar",
  "esito",
  "esliz",
  "esodo",
  "espía",
  "esquí",
  "estañ",
  "estar",
  "estau",
  "estiu",
  "estol",
  "esvol",
  "etapa",
  "etica",
  "exida",
  "exito",
  "exodo",
  "ezema",
  "fabar",
  "fabas",
  "fabla",
  "fabón",
  "facha",
  "fador",
  "fagot",
  "faina",
  "faixa",
  "faixo",
  "falaz",
  "falca",
  "falda",
  "falla",
  "fallo",
  "falsa",
  "falta",
  "fanfo",
  "fango",
  "farda",
  "fardo",
  "farga",
  "farol",
  "fasti",
  "fatal",
  "fauna",
  "favor",
  "faxar",
  "faxón",
  "fayar",
  "fázil",
  "feble",
  "fecal",
  "fecha",
  "feche",
  "fefet",
  "feixa",
  "feixo",
  "Felis",
  "felís",
  "feliz",
  "felze",
  "femal",
  "femar",
  "femer",
  "fémur",
  "fenal",
  "fendo",
  "fenir",
  "fenol",
  "feraz",
  "ferfé",
  "feria",
  "ferir",
  "ferri",
  "ferum",
  "ferún",
  "feudo",
  "fiare",
  "ficar",
  "ficha",
  "ficós",
  "fidel",
  "fideu",
  "fiemo",
  "figau",
  "fígol",
  "figón",
  "filar",
  "filet",
  "finar",
  "finca",
  "finta",
  "firma",
  "firme",
  "fisco",
  "fitar",
  "fixar",
  "fizar",
  "fizón",
  "fizor",
  "flama",
  "flato",
  "fllor",
  "flora",
  "floro",
  "flota",
  "flúor",
  "fobia",
  "fogar",
  "fogón",
  "folía",
  "folio",
  "folla",
  "foñas",
  "fonda",
  "fondo",
  "foner",
  "fongo",
  "fonre",
  "foral",
  "forau",
  "foraz",
  "forca",
  "forga",
  "forma",
  "forno",
  "forón",
  "forro",
  "forsa",
  "fosal",
  "fosil",
  "fósil",
  "fote!",
  "foter",
  "fotín",
  "fotre",
  "fovar",
  "fovia",
  "foyar",
  "foyón",
  "fraga",
  "frara",
  "frari",
  "frase",
  "frato",
  "freír",
  "freno",
  "fresa",
  "frexe",
  "freza",
  "friír",
  "frior",
  "fruta",
  "fruto",
  "fubor",
  "fuego",
  "fuell",
  "fuera",
  "fuero",
  "fuesa",
  "fueva",
  "fugaz",
  "fuida",
  "fuina",
  "fuire",
  "fulco",
  "fulla",
  "fumar",
  "fumos",
  "funda",
  "funir",
  "fuñir",
  "furco",
  "furia",
  "furno",
  "furor",
  "furto",
  "fusal",
  "fusta",
  "fútil",
  "futín",
  "futir",
  "futre",
  "fuyir",
  "gabia",
  "gacho",
  "gafar",
  "gafas",
  "gafón",
  "gaire",
  "gaita",
  "galet",
  "galga",
  "gallé",
  "gallo",
  "galop",
  "galox",
  "galze",
  "gamba",
  "ganar",
  "ganca",
  "ganga",
  "garba",
  "garbo",
  "garda",
  "garfi",
  "gáriz",
  "garmo",
  "garra",
  "garra",
  "garsa",
  "garuz",
  "gasca",
  "gasco",
  "gasto",
  "gleba",
  "glera",
  "globo",
  "godia",
  "goina",
  "golas",
  "goler",
  "gollé",
  "golón",
  "golpe",
  "gorga",
  "gorgo",
  "gorra",
  "gorro",
  "gosar",
  "goset",
  "goted",
  "gotet",
  "goyar",
  "graba",
  "grada",
  "grama",
  "grame",
  "gramo",
  "grané",
  "grano",
  "grasa",
  "grave",
  "grech",
  "greda",
  "greix",
  "greña",
  "griba",
  "grieu",
  "gripe",
  "grito",
  "groma",
  "gromo",
  "gruba",
  "gruda",
  "gruga",
  "gruma",
  "grumo",
  "grupo",
  "guans",
  "guata",
  "gubia",
  "guiar",
  "guijo",
  "guisa",
  "guita",
  "guite",
  "guixa",
  "gumia",
  "gusán",
  "gusto",
  "guzma",
  "helio",
  "hidra",
  "ibert",
  "icono",
  "ideya",
  "idolo",
  "ierón",
  "igual",
  "illón",
  "impar",
  "incha",
  "invío",
  "Irene",
  "istmo",
  "ixada",
  "ixame",
  "ixata",
  "ixato",
  "ixayo",
  "ixena",
  "ixopo",
  "ixota",
  "izión",
  "jabla",
  "jadón",
  "jalma",
  "jaque",
  "jarca",
  "jaula",
  "jenzo",
  "jersé",
  "jibar",
  "jibo!",
  "jilda",
  "jobar",
  "jodía",
  "jopar",
  "jope!",
  "joreo",
  "judía",
  "juela",
  "jufre",
  "junta",
  "kappa",
  "kéfir",
  "kirie",
  "labia",
  "labio",
  "labor",
  "lacai",
  "lacha",
  "lacra",
  "lacre",
  "laera",
  "lamia",
  "lamín",
  "lampa",
  "lanar",
  "landa",
  "lanza",
  "lanze",
  "lápiz",
  "lardo",
  "lasán",
  "latén",
  "látex",
  "latín",
  "latón",
  "launa",
  "lavar",
  "lazet",
  "leche",
  "legal",
  "legar",
  "legua",
  "leito",
  "lejos",
  "lenau",
  "leñer",
  "leñón",
  "lente",
  "lepra",
  "lerma",
  "lesna",
  "letal",
  "letra",
  "leván",
  "levar",
  "lexía",
  "lexos",
  "leyal",
  "leyer",
  "leyir",
  "lezna",
  "liana",
  "liaza",
  "libar",
  "libel",
  "libra",
  "libre",
  "libro",
  "licor",
  "licot",
  "lidio",
  "liena",
  "ligar",
  "limbo",
  "limón",
  "linar",
  "linfa",
  "linia",
  "lirol",
  "lista",
  "litón",
  "litro",
  "lixar",
  "lizeu",
  "llaco",
  "llana",
  "llapa",
  "llart",
  "llasa",
  "llaso",
  "llata",
  "llazo",
  "lleba",
  "lleña",
  "llera",
  "lliga",
  "llima",
  "llino",
  "lliza",
  "llomo",
  "llosa",
  "lluen",
  "lluna",
  "lluns",
  "lobar",
  "local",
  "logar",
  "logro",
  "loira",
  "lonja",
  "lorca",
  "lorsa",
  "lorza",
  "lozir",
  "lucar",
  "lucro",
  "ludía",
  "lueca",
  "luego",
  "lugar",
  "luita",
  "lujau",
  "lular",
  "lunar",
  "lunes",
  "luogo",
  "lupia",
  "luria",
  "lurte",
  "lusco",
  "luzir",
  "macar",
  "mache",
  "macho",
  "mader",
  "madre",
  "madri",
  "mafia",
  "malar",
  "malla",
  "mallo",
  "malta",
  "malte",
  "malva",
  "mampa",
  "mamut",
  "manar",
  "manas",
  "manau",
  "manca",
  "manda",
  "mando",
  "manes",
  "manet",
  "manga",
  "mango",
  "manía",
  "manil",
  "manta",
  "manto",
  "marca",
  "marea",
  "mariu",
  "marla",
  "marlo",
  "marma",
  "marro",
  "marso",
  "marta",
  "marts",
  "marzo",
  "masar",
  "masco",
  "masto",
  "matar",
  "matiz",
  "matón",
  "maula",
  "maxia",
  "mayor",
  "mecha",
  "medir",
  "medro",
  "mejor",
  "melar",
  "melón",
  "melsa",
  "menar",
  "meneo",
  "menor",
  "menos",
  "mente",
  "merar",
  "merda",
  "merzé",
  "mesar",
  "mesón",
  "metal",
  "metat",
  "meter",
  "metre",
  "metro",
  "meyán",
  "meyar",
  "miaja",
  "micas",
  "micra",
  "midir",
  "midón",
  "miedo",
  "milar",
  "Milia",
  "Milio",
  "milla",
  "millo",
  "minar",
  "mioja",
  "miope",
  "mirar",
  "mirra",
  "misal",
  "misil",
  "mitat",
  "mitin",
  "mitón",
  "mitra",
  "móbil",
  "mocar",
  "modos",
  "mofla",
  "molar",
  "molde",
  "moler",
  "molín",
  "molón",
  "molsa",
  "momia",
  "monde",
  "monís",
  "monte",
  "monto",
  "moral",
  "morar",
  "moret",
  "morga",
  "morir",
  "morra",
  "morro",
  "morsa",
  "mosal",
  "mosca",
  "mosen",
  "mosta",
  "mosto",
  "motel",
  "motor",
  "moure",
  "mover",
  "moxar",
  "moyén",
  "muara",
  "mudar",
  "muela",
  "muera",
  "muesa",
  "mueso",
  "mugar",
  "muire",
  "muixo",
  "Mulos",
  "mundo",
  "muñón",
  "munta",
  "mural",
  "murau",
  "murga",
  "musco",
  "museu",
  "muslo",
  "mutar",
  "mutis",
  "muxol",
  "muyir",
  "nacra",
  "nacre",
  "Nadal",
  "nadar",
  "nadie",
  "nadir",
  "nadón",
  "nafla",
  "ñafla",
  "nafra",
  "nafta",
  "naide",
  "naipe",
  "nalga",
  "namás",
  "nansa",
  "napal",
  "napar",
  "ñapir",
  "nariz",
  "nasal",
  "natal",
  "naval",
  "naxer",
  "nazer",
  "Nazia",
  "Nazio",
  "ñebla",
  "nedio",
  "negar",
  "neral",
  "nervi",
  "nesga",
  "netol",
  "nevar",
  "niata",
  "nibel",
  "nicho",
  "nidal",
  "nieda",
  "niedo",
  "nieve",
  "noble",
  "noira",
  "noite",
  "nomás",
  "només",
  "nople",
  "noria",
  "norma",
  "norte",
  "notar",
  "noval",
  "novel",
  "nozer",
  "nuara",
  "núbel",
  "núbil",
  "nuble",
  "ñudar",
  "nudet",
  "nuera",
  "nueve",
  "nugar",
  "nular",
  "nunca",
  "nuria",
  "oasis",
  "obito",
  "obolo",
  "obrar",
  "obrir",
  "ocaso",
  "odiar",
  "ofise",
  "ofrir",
  "olipa",
  "oliva",
  "ombre",
  "onrar",
  "opalo",
  "opera",
  "optar",
  "orada",
  "orbel",
  "orden",
  "ordio",
  "oriar",
  "orién",
  "ormar",
  "orpel",
  "orrar",
  "orrio",
  "orror",
  "ortal",
  "orzón",
  "osma!",
  "ospa!",
  "ostia",
  "ostil",
  "ostra",
  "ovalo",
  "ovulo",
  "ozión",
  "ozono",
  "pabía",
  "Pabla",
  "Pablo",
  "pache",
  "pacto",
  "padre",
  "padri",
  "pagar",
  "paine",
  "palco",
  "palio",
  "palla",
  "pallé",
  "palma",
  "palmo",
  "pampa",
  "pampe",
  "pañal",
  "panar",
  "panel",
  "panet",
  "pansa",
  "panta",
  "panza",
  "papel",
  "paper",
  "papis",
  "parar",
  "paret",
  "paria",
  "parir",
  "parla",
  "parra",
  "parte",
  "parte",
  "parti",
  "parto",
  "parva",
  "pasar",
  "pasmo",
  "pasta",
  "pasto",
  "patac",
  "patén",
  "patio",
  "patir",
  "patro",
  "patro",
  "Paula",
  "Paulo",
  "pausa",
  "pauta",
  "pauto",
  "pavía",
  "paxón",
  "payes",
  "payet",
  "payul",
  "pazín",
  "peazo",
  "pebre",
  "pecar",
  "pecau",
  "pecha",
  "peche",
  "pedal",
  "peder",
  "pedir",
  "pedra",
  "pedré",
  "pedro",
  "pegar",
  "pegau",
  "peine",
  "peita",
  "peito",
  "peixe",
  "pelar",
  "pella",
  "pelma",
  "penal",
  "penar",
  "penca",
  "penco",
  "penna",
  "peñón",
  "penre",
  "perén",
  "perla",
  "perno",
  "perra",
  "perún",
  "pesar",
  "peste",
  "petar",
  "peted",
  "petén",
  "petet",
  "petín",
  "petiu",
  "pexar",
  "pexet",
  "peyor",
  "pezón",
  "pials",
  "piano",
  "piazo",
  "picán",
  "picar",
  "picha",
  "picol",
  "picor",
  "picos",
  "picot",
  "pidir",
  "piejo",
  "piesa",
  "pieza",
  "pifar",
  "pigre",
  "pilar",
  "pilla",
  "pilma",
  "pilón",
  "pinar",
  "pinet",
  "piñol",
  "piñón",
  "pinta",
  "pinte",
  "pinte",
  "pinza",
  "piola",
  "pipar",
  "pipón",
  "pique",
  "pirol",
  "pisar",
  "pisca",
  "pised",
  "piser",
  "pisón",
  "pista",
  "pisto",
  "pitos",
  "pitra",
  "pivot",
  "pixal",
  "pixar",
  "pixín",
  "pixón",
  "pizca",
  "pizco",
  "pizia",
  "placa",
  "plaga",
  "plata",
  "plato",
  "plaxa",
  "plaza",
  "plazo",
  "plebe",
  "plega",
  "plego",
  "plepa",
  "pleta",
  "plica",
  "pllat",
  "pllom",
  "plomo",
  "ploro",
  "pluma",
  "pocha",
  "podar",
  "poder",
  "podio",
  "podón",
  "poema",
  "poeta",
  "polar",
  "polca",
  "polen",
  "polir",
  "Póliz",
  "polla",
  "pollo",
  "polón",
  "polpa",
  "polvo",
  "pompa",
  "ponén",
  "poner",
  "ponre",
  "popar",
  "popuz",
  "porán",
  "porca",
  "porcá",
  "porga",
  "porro",
  "porte",
  "posar",
  "posón",
  "posta",
  "poste",
  "potén",
  "potez",
  "potra",
  "poyal",
  "pozal",
  "pozar",
  "prato",
  "preba",
  "prebo",
  "preño",
  "presa",
  "preza",
  "prisa",
  "probo",
  "prole",
  "prosa",
  "pruna",
  "pubis",
  "pudir",
  "pudor",
  "pueya",
  "pueyo",
  "pugón",
  "pulga",
  "pulir",
  "pulpo",
  "pulsa",
  "pulso",
  "puñal",
  "puñau",
  "punda",
  "punir",
  "punta",
  "punto",
  "puput",
  "purna",
  "purra",
  "pusar",
  "putir",
  "puxán",
  "puxar",
  "puyal",
  "puyar",
  "puyón",
  "puzle",
  "quena",
  "quera",
  "queso",
  "quexa",
  "quién",
  "quilo",
  "quina",
  "quito",
  "rabal",
  "rabel",
  "rabia",
  "rabín",
  "racha",
  "rachá",
  "rader",
  "radil",
  "radio",
  "radiz",
  "rafal",
  "Rafel",
  "ráfel",
  "ráfil",
  "ragón",
  "rajar",
  "ralda",
  "ralla",
  "rallo",
  "ramal",
  "ramat",
  "Ramón",
  "rampa",
  "randa",
  "rango",
  "rapar",
  "rapto",
  "rasal",
  "rasar",
  "raset",
  "rasgo",
  "raspa",
  "ratar",
  "ratet",
  "ravés",
  "rayar",
  "rayo!",
  "rayón",
  "razar",
  "razén",
  "razón",
  "reata",
  "rebel",
  "rebol",
  "rebui",
  "rebul",
  "rebuñ",
  "rebús",
  "recau",
  "recha",
  "redir",
  "redol",
  "redor",
  "redut",
  "refer",
  "refús",
  "regal",
  "regar",
  "regla",
  "regle",
  "reino",
  "reixa",
  "releo",
  "rella",
  "relox",
  "remar",
  "Remón",
  "remor",
  "renal",
  "renax",
  "renda",
  "reñir",
  "rento",
  "repás",
  "repeu",
  "reple",
  "repui",
  "resar",
  "resón",
  "resta",
  "retal",
  "retar",
  "retén",
  "retet",
  "reuca",
  "reuma",
  "reyal",
  "rezar",
  "riada",
  "ribet",
  "riego",
  "riera",
  "riero",
  "rifar",
  "rifle",
  "rifol",
  "rigor",
  "rileu",
  "rimar",
  "riñón",
  "ripio",
  "risca",
  "risgo",
  "risio",
  "rispe",
  "ritmo",
  "riure",
  "rival",
  "rizar",
  "rizio",
  "robar",
  "robín",
  "robot",
  "rocho",
  "rodal",
  "rodar",
  "rodet",
  "rodil",
  "rodón",
  "roflo",
  "rogar",
  "roido",
  "roina",
  "rolda",
  "rolde",
  "rollo",
  "romal",
  "rombo",
  "ronal",
  "roñán",
  "ronar",
  "roñar",
  "ronca",
  "ronda",
  "roñiu",
  "rosca",
  "roser",
  "rosta",
  "rotar",
  "rotiu",
  "rozar",
  "rozín",
  "ruaca",
  "rubor",
  "rudio",
  "rueca",
  "rueda",
  "ruedo",
  "rueña",
  "rugar",
  "rugón",
  "ruina",
  "ruixa",
  "rular",
  "rumba",
  "rumbo",
  "rural",
  "rusac",
  "rusar",
  "rusca",
  "rusco",
  "rusir",
  "rutir",
  "rutiu",
  "ruxar",
  "ruxón",
  "sabar",
  "saber",
  "sable",
  "sabón",
  "sabre",
  "sabre",
  "sacar",
  "sacre",
  "sagaz",
  "saíno",
  "salar",
  "saldo",
  "salir",
  "salón",
  "salre",
  "salsa",
  "salse",
  "salto",
  "salut",
  "salze",
  "sambi",
  "sanar",
  "saper",
  "sapia",
  "sapte",
  "sarda",
  "sarga",
  "sarna",
  "sarpa",
  "sarra",
  "sarro",
  "sasón",
  "satén",
  "sayal",
  "sayar",
  "sazón",
  "secar",
  "secre",
  "secta",
  "sedán",
  "seder",
  "segal",
  "ségal",
  "segar",
  "selga",
  "sella",
  "selos",
  "selva",
  "señal",
  "señar",
  "senau",
  "senda",
  "senil",
  "senra",
  "senso",
  "sepia",
  "serca",
  "serie",
  "serro",
  "seseo",
  "setse",
  "sidra",
  "siede",
  "siega",
  "sielo",
  "siero",
  "siete",
  "sieto",
  "sifón",
  "sigla",
  "silga",
  "silla",
  "sillo",
  "simén",
  "símil",
  "siñal",
  "siñán",
  "sinar",
  "siñar",
  "sinno",
  "sinse",
  "sirar",
  "sirga",
  "sirio",
  "sirot",
  "sisal",
  "sisca",
  "sisón",
  "sista",
  "sitio",
  "siulo",
  "soaso",
  "sobar",
  "sobir",
  "sobre",
  "soflo",
  "solar",
  "solaz",
  "soler",
  "solfa",
  "sonar",
  "sonda",
  "sonio",
  "soniu",
  "sopar",
  "soplo",
  "soque",
  "sorbo",
  "sorna",
  "sorra",
  "sotil",
  "suave",
  "sucar",
  "sucre",
  "sudar",
  "sudor",
  "suela",
  "suelo",
  "sulco",
  "sumar",
  "sumir",
  "suque",
  "surra",
  "tabal",
  "tabán",
  "tabla",
  "table",
  "tabut",
  "tacar",
  "tacha",
  "tacho",
  "tacón",
  "tafal",
  "tagán",
  "talán",
  "talar",
  "talco",
  "talla",
  "talle",
  "tallo",
  "talón",
  "talús",
  "tamén",
  "tampó",
  "tanar",
  "tanca",
  "tanda",
  "tañer",
  "tango",
  "tañiu",
  "tapar",
  "tapet",
  "tapia",
  "tapoc",
  "tapón",
  "tarde",
  "tardi",
  "taril",
  "tarna",
  "tarso",
  "tarta",
  "tarul",
  "tasar",
  "tasca",
  "taste",
  "tauba",
  "taula",
  "tauro",
  "taute",
  "tazón",
  "techo",
  "tecla",
  "tedio",
  "tefla",
  "teflo",
  "teito",
  "teixo",
  "telar",
  "teler",
  "télex",
  "tella",
  "telón",
  "temer",
  "temor",
  "tenaz",
  "tenca",
  "tener",
  "tenir",
  "teñir",
  "tenis",
  "tenor",
  "tenue",
  "termi",
  "termo",
  "terna",
  "terne",
  "terra",
  "tesis",
  "testa",
  "testo",
  "tetar",
  "texer",
  "texir",
  "tiara",
  "tibar",
  "tibia",
  "tieda",
  "tifón",
  "tifus",
  "timar",
  "timba",
  "timón",
  "tinre",
  "tinte",
  "tiple",
  "tirán",
  "tirar",
  "tiria",
  "tirín",
  "tisón",
  "titán",
  "titín",
  "títol",
  "tixer",
  "tixir",
  "tizón",
  "tocán",
  "tocar",
  "tocha",
  "tocho",
  "tolba",
  "toldo",
  "tolla",
  "tollo",
  "tomar",
  "tombo",
  "tonel",
  "tóner",
  "tonga",
  "tongo",
  "topaz",
  "toque",
  "toral",
  "tórax",
  "torba",
  "tormo",
  "torna",
  "torné",
  "torno",
  "torpe",
  "torre",
  "torso",
  "torta",
  "tosca",
  "tosir",
  "total",
  "tótem",
  "tozal",
  "tozar",
  "traba",
  "trabe",
  "trago",
  "traje",
  "trama",
  "tramo",
  "traña",
  "trapa",
  "trapo",
  "trasa",
  "trato",
  "traxe",
  "traza",
  "trena",
  "trenc",
  "Tresa",
  "treta",
  "treze",
  "triar",
  "tribe",
  "tribu",
  "trica",
  "triga",
  "trigo",
  "tripa",
  "trobo",
  "troca",
  "troco",
  "trole",
  "trono",
  "tropa",
  "tropo",
  "trote",
  "troxo",
  "truca",
  "truco",
  "truen",
  "trufa",
  "trusa",
  "tuara",
  "tucón",
  "tuera",
  "tuero",
  "tular",
  "tumar",
  "tumba",
  "tumbo",
  "tumor",
  "túnel",
  "turba",
  "turca",
  "turir",
  "turma",
  "turmo",
  "turón",
  "turra",
  "tusal",
  "tusir",
  "tusmo",
  "tusta",
  "tutar",
  "tuteo",
  "uarta",
  "ubrir",
  "uchar",
  "uebra",
  "uegar",
  "ueito",
  "uella",
  "uello",
  "uerta",
  "uerto",
  "uesca",
  "ueste",
  "ufega",
  "uiski",
  "uixar",
  "ultra",
  "umero",
  "unada",
  "uñada",
  "undar",
  "uñero",
  "uñeta",
  "ungla",
  "unión",
  "unlla",
  "untar",
  "Urano",
  "Úrbez",
  "urdir",
  "urmal",
  "urnia",
  "uromo",
  "urora",
  "urra!",
  "urror",
  "uscar",
  "usina",
  "usual",
  "usura",
  "uzena",
  "vabor",
  "vacal",
  "vacán",
  "vache",
  "vagar",
  "vagón",
  "vaina",
  "valer",
  "valía",
  "valor",
  "valre",
  "vapor",
  "varar",
  "varío",
  "varón",
  "vasau",
  "vased",
  "vaset",
  "vasiu",
  "vatio",
  "vedar",
  "vedau",
  "veder",
  "veila",
  "veire",
  "vello",
  "velón",
  "veloz",
  "venal",
  "venar",
  "venda",
  "vende",
  "vendí",
  "vener",
  "venia",
  "venir",
  "venre",
  "vente",
  "veral",
  "veraz",
  "verbo",
  "vermú",
  "verso",
  "vetar",
  "vetel",
  "veter",
  "vevir",
  "vexar",
  "veyer",
  "vidén",
  "vidio",
  "vidre",
  "vieda",
  "viega",
  "viero",
  "vigor",
  "villa",
  "vinre",
  "viola",
  "viril",
  "virol",
  "virus",
  "visar",
  "visca",
  "visco",
  "visón",
  "visor",
  "vista",
  "vital",
  "vitau",
  "Vítor",
  "viure",
  "vivac",
  "vivaz",
  "viver",
  "vivir",
  "viyer",
  "Vizén",
  "vizio",
  "vocal",
  "vogal",
  "volar",
  "voleo",
  "vomec",
  "voraz",
  "votán",
  "votar",
  "vudar",
  "vulco",
  "vulgo",
  "vusté",
  "vutre",
  "xabón",
  "xadón",
  "xampú",
  "xanca",
  "xaque",
  "xarga",
  "xarra",
  "xarro",
  "xenzo",
  "xerez",
  "xirga",
  "xisca",
  "xucar",
  "xuela",
  "xufre",
  "xugar",
  "xutar",
  "yegua",
  "yerba",
  "yerno",
  "yeyué",
  "yunta",
  "zabar",
  "zafra",
  "zagal",
  "zaica",
  "zarpa",
  "zeazo",
  "zebao",
  "zebra",
  "zedén",
  "zedro",
  "zeica",
  "zejas",
  "zelda",
  "zelga",
  "zella",
  "zello",
  "zelpa",
  "zelta",
  "zemal",
  "zenar",
  "zeñar",
  "zeñir",
  "zenit",
  "zenso",
  "zerca",
  "zerco",
  "zerol",
  "zerpa",
  "zerra",
  "zerro",
  "zerut",
  "zesán",
  "zesar",
  "Zésar",
  "zespé",
  "zesta",
  "zesto",
  "zetre",
  "zetro",
  "zevil",
  "ziazo",
  "zibar",
  "ziclo",
  "zieca",
  "zielo",
  "zifra",
  "Zilia",
  "Zilio",
  "zilio",
  "zilla",
  "zillo",
  "zinca",
  "zinco",
  "zinta",
  "zinto",
  "zirco",
  "zirio",
  "zisar",
  "zisca",
  "zisma",
  "zisne",
  "zitar",
  "zivil",
  "zizar",
  "zizón",
  "zofra",
  "zofre",
  "zolle",
  "zoque",
  "zucre",
  "zueca",
  "zueco",
  "zufre",
  "zunze",
  "zurra",
  "zurro",
  
  ];
  