import styles from "./modal.module.scss";
import React from "react"

interface Props {
  onClick: (e: any) => void;
}

let btnClass = [
  'btn',
  'btn-primary',
  'btn-info'
]

const Instructions: React.FC<Props> = ({ onClick }) => (
  <>
  <div className={styles.modalViewContainer}>
    <div className={styles.modalContainer} >
      <h2>Cómo chugar?</h2>
      <div className={styles.paragraph}>
        <p>Preba de devinar a PAROLA en 6 intentos.</p>
        <p>Cada vegada que prebes ha d'estar una parola valida de 5 letras. Preta o botón "PREBAR" pa chugar.</p>
        <p>Dimpués de cada intento, a color d'as fichas cambiará pa amostrar en que mida ye estato o tuyo intento amán d'enzertar a parola amagata.</p>
        <p>Ta simplificar o chuego as parolas no tienen tildes e s'amostran siempre en mayusclas.</p>
        <p>Si pretas o botón "catrinalla" a parola a devinar será dentro d'una lista de 300 parolas d'uso cutiano en cuenta d'entre más de 2500 parolas.</p>
        <p>Exemplos:</p>
        <img src="./wordle_verde.png" />
        <p>A letra D ye en a parola y en o puesto correuto.</p>
        <img src="./wordle_amarillo.png" />
        <p>A letra A ye en a parola pero en o puesto equivocau.</p>
        <img src="./wordle_gris.png" />
        <p>Denguna letra ye en a parola en garra puesto.</p>       
      </div>
      <div className={styles.centerButton} >
      <button onClick={onClick} className={styles.closeButton}>Trancar</button>
      </div>
    </div>
  </div>     
  </>
);

export default Instructions;




