export const TARGET = [
    "abaix",
    "abaxo",
    "abete",
    "abiar",
    "abozo",
    "abugo",
    "achar",
    "achén",
    "adoba",
    "adobo",
    "adoga",
    "adote",
    "aduya",
    "afear",
    "afixo",
    "afogo",
    "afora",
    "Agnés",
    "agora",
    "aigua",
    "aimar",
    "Ainés",
    "airaz",
    "airón",
    "airot",
    "aixir",
    "ajada",
    "alada",
    "alaxa",
    "albar",
    "albor",
    "albur",
    "aldar",
    "alera",
    "alfil",
    "aliar",
    "alica",
    "aliga",
    "almud",
    "almut",
    "almuz",
    "aloda",
    "aloix",
    "alora",
    "alsar",
    "alzar",
    "alzau",
    "amaca",
    "amago",
    "amare",
    "ambra",
    "ambre",
    "amiro",
    "ampra",
    "ampre",
    "añada",
    "añata",
    "ancón",
    "andar",
    "aneba",
    "aneda",
    "anede",
    "añeta",
    "aneto",
    "anglo",
    "anque",
    "ansar",
    "ansir",
    "antis",
    "antro",
    "anuei",
    "anuet",
    "anzía",
    "apaño",
    "apero",
    "aquer",
    "ardit",
    "arena",
    "argón",
    "aribo",
    "ariet",
    "arizo",
    "arnal",
    "arras",
    "arreo",
    "arres",
    "arreu",
    "arriu",
    "arrós",
    "artal",
    "artar",
    "arzén",
    "ascla",
    "asero",
    "aseyo",
    "asial",
    "asina",
    "aspar",
    "ature",
    "aucar",
    "audaz",
    "augua",
    "aunir",
    "aupar",
    "aveza",
    "aviar",
    "avién",
    "axada",
    "axizo",
    "axuar",
    "ayere",
    "babón",
    "babor",
    "bacal",
    "bacán",
    "badal",
    "badar",
    "badía",
    "badil",
    "baixo",
    "bajes",
    "balda",
    "balde",
    "balot",
    "balsa",
    "baxán",
    "baxar",
    "bazía",
    "bazín",
    "becar",
    "becha",
    "beire",
    "belar",
    "beliu",
    "belún",
    "besar",
    "beure",
    "bieco",
    "biega",
    "biera",
    "bigot",
    "binar",
    "biola",
    "birla",
    "birol",
    "birot",
    "bisbe",
    "bisca",
    "bisco",
    "bisel",
    "bocau",
    "boche",
    "bofio",
    "bogal",
    "bogar",
    "boira",
    "boñar",
    "bonet",
    "borda",
    "bosar",
    "breca",
    "brete",
    "brilo",
    "brisa",
    "broxa",
    "bruxa",
    "bubaz",
    "buche",
    "buega",
    "bueña",
    "bufar",
    "buixe",
    "buixo",
    "butir",
    "buxía",
    "buyol",
    "cacal",
    "cafre",
    "caire",
    "caixa",
    "caixo",
    "calre",
    "camau",
    "camín",
    "campo",
    "canet",
    "canta",
    "capar",
    "caper",
    "capil",
    "capir",
    "capón",
    "catén",
    "catre",
    "caxal",
    "caxar",
    "caxón",
    "caxot",
    "cayer",
    "chaca",
    "chaco",
    "chada",
    "chama",
    "chaus",
    "chaza",
    "chelo",
    "chera",
    "chesa",
    "cheso",
    "chiba",
    "chilo",
    "chiro",
    "chita",
    "chito",
    "chiva",
    "choca",
    "chola",
    "choro",
    "Chuan",
    "chubo",
    "chuco",
    "chugo",
    "chupa",
    "chupo",
    "Chusé",
    "chuta",
    "chuza",
    "chuzo",
    "cleca",
    "cobar",
    "cobil",
    "colla",
    "coxín",
    "coxón",
    "craba",
    "crapa",
    "creba",
    "crera",
    "cuasi",
    "cucar",
    "cuita",
    "cuixa",
    "cursa",
    "dalla",
    "debán",
    "dende",
    "dexar",
    "dillá",
    "diner",
    "dixar",
    "dizir",
    "dople",
    "dozén",
    "Dulza",
    "enuyo",
    "erzio",
    "esbar",
    "esbol",
    "escar",
    "esfer",
    "esgar",
    "estiu",
    "estol",
    "fabar",
    "fabas",
    "fabla",
    "fabón",
    "faina",
    "faixa",
    "faixo",
    "falca",
    "falsa",
    "fardo",
    "faxar",
    "faxón",
    "fayar",
    "feble",
    "fecal",
    "fecha",
    "feche",
    "feixa",
    "feixo",
    "femar",
    "fendo",
    "fiemo",
    "figau",
    "filar",
    "filet",
    "fitar",
    "fixar",
    "fizar",
    "flama",
    "fogar",
    "fongo",
    "forau",
    "foraz",
    "forca",
    "forga",
    "forma",
    "forno",
    "forón",
    "foter",
    "fotre",
    "frexe",
    "fuesa",
    "fueva",
    "fuina",
    "fuire",
    "fulco",
    "fulla",
    "fuñir",
    "fusta",
    "fuyir",
    "gafar",
    "garmo",
    "glera",
    "goler",
    "gosar",
    "ideya",
    "ixada",
    "ixame",
    "ixata",
    "ixato",
    "ixayo",
    "ixena",
    "ixopo",
    "ixota",
    "jabla",
    "jadón",
    "jalma",
    "jarca",
    "jibar",
    "jobar",
    "jopar",
    "joreo",
    "lamín",
    "lexía",
    "lexos",
    "leyal",
    "leyer",
    "lezna",
    "libel",
    "linia",
    "lueca",
    "luita",
    "lurte",
    "lusco",
    "mallo",
    "malta",
    "maxia",
    "medro",
    "melsa",
    "menar",
    "merar",
    "merda",
    "mesar",
    "metat",
    "meyán",
    "meyar",
    "miaja",
    "micas",
    "midir",
    "midón",
    "Milia",
    "Milio",
    "mioja",
    "misal",
    "mitat",
    "mitón",
    "mocar",
    "mofla",
    "moret",
    "morga",
    "mosen",
    "moxar",
    "moyén",
    "muara",
    "muesa",
    "mueso",
    "mugar",
    "muire",
    "muixo",
    "murau",
    "murga",
    "musco",
    "museu",
    "muxol",
    "muyir",
    "nacra",
    "nacre",
    "Nadal",
    "naxer",
    "Nazia",
    "Nazio",
    "ñebla",
    "nedio",
    "neral",
    "niata",
    "nomás",
    "només",
    "nople",
    "nuara",
    "ordio",
    "ortal",
    "pansa",
    "panta",
    "paret",
    "paxón",
    "payes",
    "payet",
    "payul",
    "pazín",
    "peazo",
    "pebre",
    "pecau",
    "peita",
    "peito",
    "peixe",
    "penca",
    "perén",
    "petar",
    "petén",
    "petet",
    "petín",
    "petiu",
    "pexar",
    "pexet",
    "peyor",
    "pials",
    "piazo",
    "picán",
    "picha",
    "picol",
    "piejo",
    "piesa",
    "pifar",
    "pigre",
    "pilón",
    "pinet",
    "piñol",
    "pisón",
    "pitos",
    "pitra",
    "pixal",
    "pixar",
    "pixín",
    "pixón",
    "pizca",
    "pizia",
    "plaxa",
    "plega",
    "plego",
    "plepa",
    "pleta",
    "plica",
    "pocha",
    "porro",
    "posar",
    "preba",
    "pueyo",
    "puñau",
    "punda",
    "punir",
    "puput",
    "purna",
    "purra",
    "pusar",
    "putir",
    "puxán",
    "puxar",
    "puyal",
    "puyar",
    "puyón",
    "quena",
    "quera",
    "quexa",
    "quilo",
    "quina",
    "rabal",
    "rabín",
    "rader",
    "radil",
    "radiz",
    "rafal",
    "Rafel",
    "ráfel",
    "ráfil",
    "ragón",
    "ralda",
    "randa",
    "rasal",
    "rasar",
    "raset",
    "ratar",
    "ratet",
    "ravés",
    "rayón",
    "razar",
    "razén",
    "razón",
    "reata",
    "rebel",
    "rebol",
    "rebui",
    "rebul",
    "rebús",
    "recau",
    "recha",
    "redir",
    "redol",
    "redor",
    "redut",
    "refer",
    "Remón",
    "repui",
    "ribet",
    "ripio",
    "rispe",
    "riure",
    "robín",
    "rocho",
    "roina",
    "rolde",
    "romal",
    "ronal",
    "ronar",
    "roñar",
    "ronda",
    "roñiu",
    "rotiu",
    "rozín",
    "ruaca",
    "rudio",
    "rueca",
    "ruixa",
    "rular",
    "rusar",
    "rusir",
    "rutir",
    "rutiu",
    "ruxar",
    "ruxón",
    "sacre",
    "saíno",
    "salar",
    "saper",
    "sapia",
    "sapte",
    "sarda",
    "sarga",
    "sarra",
    "sasón",
    "satén",
    "sayal",
    "sayar",
    "secre",
    "segal",
    "selga",
    "selva",
    "señar",
    "senau",
    "simén",
    "siñal",
    "siñán",
    "sinar",
    "siñar",
    "sirga",
    "sisal",
    "sisón",
    "soniu",
    "sorna",
    "sucre",
    "sulco",
    "suque",
    "surra",
    "tabal",
    "tañer",
    "tañiu",
    "tardi",
    "tarso",
    "texer",
    "tiple",
    "tixer",
    "tixir",
    "tocho",
    "tollo",
    "tormo",
    "torna",
    "tozal",
    "traxe",
    "trena",
    "Tresa",
    "troxo",
    "truca",
    "truen",
    "tuara",
    "tucón",
    "turma",
    "turmo",
    "tusir",
    "tusmo",
    "tusta",
    "tutar",
    "ubrir",
    "uebra",
    "uegar",
    "ueito",
    "uella",
    "uello",
    "uesca",
    "uixar",
    "ungla",
    "Úrbez",
    "vedau",
    "vexar",
    "veyer",
    "vieda",
    "viega",
    "Vítor",
    "viyer",
    "Vizén",
    "Zilia",
    "Zilio",
    "zinca",
    "zoque",
    "zucre",
    "zueca",
    "zufre",     
];
